import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "../Slices/authSlice";
import commonReducer from "../Slices/commonSlice";
import userActionsReducer from "../Slices/userActions";

export default configureStore({
  reducer: {
    auth: authReducer,
    common: commonReducer,
    userActions: userActionsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  // devTools: false,
});